@import '../../bootstrap/mixins';
// Cutoms Gray colors for theme
$gray-darker: #232735;
$gray-dark: #3a3f51;
$gray: #dde6e9;
$gray-light: #e4eaec;
$gray-lighter: #edf1f2;

// Custom brand colors for theme
$inverse: #131e26;
$purple: #8597ff;
$pink: #ffe1e1;
$yellow: rgb(251, 210, 139);
$green: #94ce69;

$color-midnight-express: #0c1b37;
$color-dark-midnight-express: #09162d;
$color-light-scarlet: #ea7765;
$color-sunset: #f15b28;
$color-koncert-white: #f5f5f5;
$color-primary-shade: #273a5c;
$color-secondary-shade: #2a3557;
$color-persian-blue: #5b45e0;

$gradient-blood-orange-start: #fbab44;
$gradient-blood-orange-end: $color-sunset;
$gradient-blood-orange-light-start: #f6c46a;
$gradient-blood-orange-light-end: #fb884d;
$gradient-turquoise-dream-start: #33ebc5;
$gradient-turquoise-dream-end: #21bfe6;
$gradient-purple-rain-start: #96a1ff;
$gradient-purple-rain-end: #4e4efb;
$gradient-baltic-sea-start: #67c4ff;
$gradient-baltic-sea-end: #1f90ff;
$gradient-brand-start: #67c4ff;
$gradient-brand-end: #1f90ff;
$gradient-gothic-sky-start: #3b3a40;
$gradient-gothic-sky-end: #62597a;

$brand-primary: $gradient-baltic-sea-start !default;
$brand-success: #94ce69 !default;
$brand-info: $gradient-baltic-sea-end !default;
$brand-warning: rgb(253, 140, 38) !default;
$brand-danger: #f45b53 !default;
$brand-success-light: #cafdda !default;
$brand-warning-light: rgb(251, 210, 139) !default;
$brand-danger-light: #ffe1e1 !default;

$primary: $gradient-baltic-sea-start;
$secondary: #fff;
$success: #94ce69;
$info: $gradient-baltic-sea-start;
$warning: $gradient-blood-orange-start;
$danger: $color-sunset;
$dark: $color-dark-midnight-express;

$color-ocean: $color-dark-midnight-express;
$color-trueblue: #fbab44;
$color-cyan: #fac47e;
$color-powder: #afe3f9;
$color-sky: #d2f2ff;
$color-white: #ffffff;
$color-charcoal: #4c4c4c;
$color-rock: #666666;
$color-grey: #999999;
$color-wolf: #cccccc;
$color-mako: #e0e0e0;
$color-silver: #f4f4f4;
$color-grass: #79c143;
$color-mint: #cafdda;
$color-perry: #8597ff;
$color-mist: #e1eaff;
$color-poppy: #f45b53;
$color-rouge: #ffe1e1;
$color-orange: rgb(253, 140, 38);
$color-orange-light: rgb(251, 210, 139);
$color-grey-border: #e4eaec;
$color-lynch: #596587;
$color-regent-gray: #6b7695;
$color-bluewood: #313b55;

//Custom Cadence Colors
$color-yellow: #fcfe60;
$color-blue: #007bff;
$color-auto-email: #5757cb;

// Custom color variants
$amountOfLight: 10%;
$amountOfDark: 15%;

$primary-light: lighten($primary, $amountOfLight);
$success-light: lighten($success, $amountOfLight);
$info-light: lighten($info, $amountOfLight);
$warning-light: lighten($warning, $amountOfLight);
$danger-light: lighten($danger, $amountOfLight);
$inverse-light: lighten($inverse, $amountOfLight);
$green-light: lighten($green, $amountOfLight);
$pink-light: lighten($pink, $amountOfLight);
$purple-light: lighten($purple, $amountOfLight);
$yellow-light: lighten($yellow, $amountOfLight);
$primary-dark: darken($primary, $amountOfLight);
$success-dark: darken($success, $amountOfLight);
$info-dark: darken($info, $amountOfLight);
$warning-dark: darken($warning, $amountOfLight);
$danger-dark: darken($danger, $amountOfLight);
$inverse-dark: darken($inverse, $amountOfLight);
$green-dark: darken($green, $amountOfLight);
$pink-dark: darken($pink, $amountOfLight);
$purple-dark: darken($purple, $amountOfLight);
$yellow-dark: darken($yellow, $amountOfLight);

$color-ocean-light: lighten($color-ocean, $amountOfLight);
$color-ocean-dark: darken($color-ocean, $amountOfLight);
$color-trueblue-light: lighten($color-trueblue, $amountOfLight);
$color-trueblue-dark: darken($color-trueblue, $amountOfLight);
$color-cyan-light: lighten($color-cyan, $amountOfLight);
$color-cyan-dark: darken($color-cyan, $amountOfLight);
$color-powder-light: lighten($color-powder, $amountOfLight);
$color-powder-dark: darken($color-powder, $amountOfLight);
$color-sky-light: lighten($color-sky, $amountOfLight);
$color-sky-dark: darken($color-sky, $amountOfLight);
$color-white-light: lighten($color-white, $amountOfLight);
$color-white-dark: darken($color-white, $amountOfLight);
$color-charcoal-light: lighten($color-charcoal, $amountOfLight);
$color-charcoal-dark: darken($color-charcoal, $amountOfLight);
$color-rock-light: lighten($color-rock, $amountOfLight);
$color-rock-dark: darken($color-rock, $amountOfLight);
$color-grey-light: lighten($color-grey, $amountOfLight);
$color-grey-dark: darken($color-grey, $amountOfLight);
$color-wolf-light: lighten($color-wolf, $amountOfLight);
$color-wolf-dark: darken($color-wolf, $amountOfLight);
$color-mako-light: lighten($color-mako, $amountOfLight);
$color-mako-dark: darken($color-mako, $amountOfLight);
$color-silver-light: lighten($color-silver, $amountOfLight);
$color-silver-dark: darken($color-silver, $amountOfLight);
$color-grass-light: lighten($color-grass, $amountOfLight);
$color-grass-dark: darken($color-grass, $amountOfLight);
$color-mint-light: lighten($color-mint, $amountOfLight);
$color-mint-dark: darken($color-mint, $amountOfLight);
$color-perry-light: lighten($color-perry, $amountOfLight);
$color-perry-dark: darken($color-perry, $amountOfLight);
$color-mist-light: lighten($color-mist, $amountOfLight);
$color-mist-dark: darken($color-mist, $amountOfLight);
$color-poppy-light: lighten($color-poppy, $amountOfLight);
$color-poppy-dark: darken($color-poppy, $amountOfLight);
$color-rouge-light: lighten($color-rouge, $amountOfLight);
$color-rouge-dark: darken($color-rouge, $amountOfLight);

//Custom Cadence Colors
$email-light: lighten($gradient-baltic-sea-end, $amountOfLight);
$email-dark: darken($gradient-baltic-sea-end, $amountOfLight);
$call-light: lighten($color-grass, $amountOfLight);
$call-dark: darken($color-grass, $amountOfLight);
$linkedin-light: lighten($gradient-purple-rain-end, $amountOfLight);
$linkedin-dark: darken($gradient-purple-rain-end, $amountOfLight);
$social-light: lighten($brand-warning, $amountOfLight);
$social-dark: darken($brand-warning, $amountOfLight);
$color-yellow-light: lighten($color-yellow, $amountOfLight);
$color-yellow-dark: darken($color-yellow, $amountOfLight);
$color-blue-light: lighten($color-blue, $amountOfLight);
$color-blue-dark: darken($color-blue, $amountOfLight);
$color-task-dark: darken($gradient-turquoise-dream-start, $amountOfLight);
$color-secondary-shade-light: lighten($color-secondary-shade, $amountOfLight);

// Theme variables

// used for sidebars and content spacing
$navbar-height: 55px;

// Button secondary redefinition style for theme
$btn-secondary-color: #333;
$btn-secondary-bg: #fff;
$btn-secondary-border: #eaeaea;

// Top Border color for custom default cards (.card-default)
$card-default-border-color: #cfdbe2;

// ---------------------
// Utilities variables
// ---------------------

$text-alpha: rgba(255, 255, 255, 0.5);
$text-alpha-inverse: rgba(0, 0, 0, 0.5);

$font-size-base: 1rem;
$text-sm: $font-size-base * 0.74375;
$text-md: $font-size-base * 1.6625;
$text-lg: $font-size-base * 2.625;
$ba-font-lg: $font-size-base * 3.5;

$border-color: rgba(0, 0, 0, 0.12);

$wd-xxs: 60px;
$wd-xs: 90px;
$wd-sm: 150px;
$wd-sd: 200px; // sd == standard
$wd-md: 240px;
$wd-lg: 280px;
$wd-xl: 320px;
$wd-xxl: 360px;
$wd-wide: 100%;
$wd-auto: auto;
$wd-zero: 0;
$wd-select: 75px;

//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
// }

// Body

$body-bg: #fff;
$body-color: $color-dark-midnight-express;

$border-radius: 0.25rem;
$border-radius-lg: 0.375rem;

$line-height-base: 1.528571429;

$headings-line-height: 1.1;

$text-muted: #999999;

// Tables

$table-accent-bg: #fafbfc;
$table-hover-bg: #f4f4f4;

$table-border-color: #eee;

// Buttons + Forms

$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: 0.3125rem;
$input-btn-padding-x-sm: 0.625rem;

$input-btn-padding-y-lg: 0.625rem;
$input-btn-padding-x-lg: 1.25rem;

$input-height: 2.1875rem; // 35px

// Forms

$input-disabled-bg: $gray-lighter;
$input-border-color: $gray;
$input-focus-border-color: #66afe9;
$input-placeholder-color: #b7bac9;

$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;

$custom-control-indicator-active-bg: lighten($primary, 25%);

// Dropdowns

$dropdown-border-color: #e1e1e1;

// Navs

$nav-tabs-link-active-bg: #fff;

// Jumbotron

$jumbotron-bg: #fcfcfc;

// List group

$list-group-border-color: #e4eaec;

// Image thumbnails

$thumbnail-padding: 0.125rem;

// Breadcrumbs

$breadcrumb-bg: #fafafa;
$breadcrumb-divider-color: #c1c2c3;
$breadcrumb-active-color: $body-color;
